var easeInOutCubic = function (t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; };
export default function scrollToElement(element, offset) {
    if (offset === void 0) { offset = 0; }
    var change = element.getBoundingClientRect().top + offset;
    if ('scrollBehavior' in document.documentElement.style && 'scrollBy' in window) {
        scrollBy({
            top: change,
            behavior: 'smooth'
        });
        return;
    }
    var duration = 1000;
    var start = window.scrollY;
    var to = start + change;
    var startDate = new Date().getTime();
    var animateScroll = function () {
        var currentDate = new Date().getTime();
        var currentTime = currentDate - startDate;
        window.scrollTo(window.scrollX, start + easeInOutCubic(currentTime / duration) * change);
        if (currentTime < duration) {
            window.requestAnimationFrame ? requestAnimationFrame(animateScroll) : animateScroll();
        }
        else {
            element.scrollTop = to;
        }
    };
    animateScroll();
}
