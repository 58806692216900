var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import scrollToElement from "./scrollto";
import SearchResultKeys from "./search_result_keys";
import EventBus from "../events/EventBus";
import { StatusClassNames } from "../types/StatusClassNames";
var URL = '/api/search';
var SearchBar = /** @class */ (function () {
    function SearchBar(searchBar, searchResults, notFoundBlock, brandsBlock, productsBlock, icon) {
        this.searchBar = searchBar;
        this.searchResults = searchResults;
        this.notFoundBlock = notFoundBlock;
        this.brandsBlock = brandsBlock;
        this.productsBlock = productsBlock;
        this.icon = icon;
        this.shadowCache = document.createElement('div');
        this.lastValue = '';
        this.cache = {};
        this.suggestionItems = {};
        this.notFound = '';
        this.timeoutId = 0;
        if (!searchResults) {
            return;
        }
        this.brandsList = brandsBlock.querySelector('.search-result__list');
        this.productsList = productsBlock.querySelector('.search-result__list');
        this.lastValue = searchBar.value.trim();
        this.keyHandler = new SearchResultKeys(searchBar, searchResults, '.search-result__item', this.onselect.bind(this));
        this.bindEvents();
        SearchBar.setAppHeight();
    }
    SearchBar.prototype.bindEvents = function () {
        var form = this.searchBar.closest('form');
        if (form) {
            form.addEventListener('submit', this.onsubmit.bind(this));
        }
        this.searchBar.addEventListener('keyup', this.onkeyup.bind(this));
        this.searchBar.addEventListener('input', this.onkeyup.bind(this));
        this.searchBar.addEventListener('blur', this.onblur.bind(this));
        this.searchBar.addEventListener('focus', this.onfocus.bind(this));
        this.searchBar.addEventListener('click', this.onfocus.bind(this));
        this.searchResults.addEventListener('focus', this.show.bind(this));
        this.searchResults.addEventListener('click', this.show.bind(this));
        this.searchBar.parentElement.querySelector('.top-search__clear').addEventListener('click', this.onclear.bind(this));
        window.addEventListener('resize', SearchBar.setAppHeight);
    };
    SearchBar.setAppHeight = function () {
        document.documentElement.style.setProperty('--app-height', "".concat(window.innerHeight, "px"));
    };
    SearchBar.prototype.hide = function () {
        this.searchResults.close();
    };
    SearchBar.prototype.show = function () {
        clearTimeout(this.timeoutId);
        this.searchResults.show();
        this.searchBar.focus();
    };
    SearchBar.prototype.toggleBlocks = function (brands, products) {
        if (brands === void 0) { brands = false; }
        if (products === void 0) { products = false; }
        if (brands) {
            this.brandsBlock.classList.remove(StatusClassNames.hidden);
        }
        else {
            this.brandsBlock.classList.add(StatusClassNames.hidden);
        }
        if (products) {
            this.productsBlock.classList.remove(StatusClassNames.hidden);
        }
        else {
            this.productsBlock.classList.add(StatusClassNames.hidden);
        }
        if (!brands && !products) {
            this.notFoundBlock.classList.remove(StatusClassNames.hidden);
        }
        else {
            this.notFoundBlock.classList.add(StatusClassNames.hidden);
        }
        if (this.searchBar.matches(':focus')) {
            this.show();
        }
    };
    SearchBar.prototype.onclear = function (event) {
        this.searchBar.value = '';
        this.searchBar.focus();
        event.preventDefault();
    };
    SearchBar.prototype.onfocus = function () {
        if (this.productsList.childElementCount || this.brandsList.childElementCount) {
            this.show();
        }
        if (screen.width <= 768) {
            scrollToElement(this.searchBar, -12);
        }
        this.onkeyup();
    };
    SearchBar.prototype.onblur = function () {
        this.timeoutId = window.setTimeout(this.hide.bind(this), 200);
    };
    SearchBar.prototype.onkeyup = function (event) {
        var _this = this;
        var txt = this.searchBar.value.trim().toLowerCase();
        if (event && !event.defaultPrevented && txt !== this.lastValue) {
            this.keyHandler.deselect();
        }
        this.lastValue = txt;
        if (txt.length < 2) {
            this.searchResults.querySelectorAll('.search-result__item').forEach(function (el) { return _this.shadowCache.appendChild(el); });
            this.hide();
            return;
        }
        if (this.cache[txt] === 'requested') {
            return;
        }
        if (this.cache[txt] !== undefined) {
            // @ts-ignore see above condition
            this.updateFromList(this.cache[txt]);
            return;
        }
        if (this.notFound && txt.startsWith(this.notFound)) {
            return;
        }
        this.makeRequest(txt);
    };
    SearchBar.prototype.onsubmit = function (event) {
        if (this.notFound) {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            return false;
        }
        if (this.searchResults.querySelectorAll('.search-result__item').length !== 1) {
            return;
        }
        var link = this.searchResults.querySelector('a');
        if (link) {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            link.click();
        }
    };
    SearchBar.prototype.onselect = function (item) {
        var _a;
        if (item) {
            (_a = item.querySelector('a')) === null || _a === void 0 ? void 0 : _a.click();
        }
        else {
            this.onsubmit();
        }
    };
    SearchBar.prototype.makeRequest = function (query) {
        return __awaiter(this, void 0, void 0, function () {
            var data, html;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cache[query] = 'requested';
                        this.showLoader();
                        if (this.abortController) {
                            this.abortController.abort('cancel');
                        }
                        this.abortController = new AbortController();
                        data = new FormData();
                        data.append('query', query);
                        return [4 /*yield*/, fetch(URL, {
                                method: 'POST',
                                credentials: 'same-origin',
                                cache: 'no-cache',
                                body: data,
                                signal: this.abortController.signal,
                            })
                                .then(function (response) {
                                _this.hideLoader();
                                return response.text();
                            })
                                .catch(function (reason) {
                                if (reason !== 'cancel') {
                                    _this.hideLoader();
                                }
                                delete _this.cache[query];
                            })];
                    case 1:
                        html = _a.sent();
                        if (!html) {
                            return [2 /*return*/];
                        }
                        if (!this.updateHtml(query, html)) {
                            this.notFound = query;
                        }
                        else {
                            this.notFound = '';
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchBar.prototype.updateHtml = function (query, html) {
        var list = this.collectElements(html);
        this.cache[query] = list;
        var result = this.updateFromList(list);
        EventBus.emit('products:load');
        return result;
    };
    SearchBar.prototype.updateFromList = function (list) {
        var _this = this;
        this.hideNotIncludedItems(list);
        var lastElement;
        var lastBrandElement;
        list.forEach(function (id) {
            var isBrand = _this.suggestionItems[id].classList.contains('search-result__item--brand');
            if (isBrand) {
                _this.brandsList.insertBefore(_this.suggestionItems[id], lastBrandElement === null || lastBrandElement === void 0 ? void 0 : lastBrandElement.nextSibling);
                lastBrandElement = _this.suggestionItems[id];
            }
            else {
                _this.productsList.insertBefore(_this.suggestionItems[id], lastElement === null || lastElement === void 0 ? void 0 : lastElement.nextSibling);
                lastElement = _this.suggestionItems[id];
            }
        });
        this.toggleBlocks(this.brandsList.childElementCount > 0, this.productsList.childElementCount > 0);
        return !(list.length === 1 && list.includes(0));
    };
    SearchBar.prototype.collectElements = function (html) {
        var _this = this;
        var list = [];
        var div = document.createElement('div');
        div.innerHTML = html;
        div.querySelectorAll('.search-result__item').forEach(function (el) {
            var id = el.dataset.id;
            if (!id) {
                return;
            }
            list.push(parseInt(id));
            if (_this.suggestionItems[id] !== undefined) {
                return;
            }
            _this.suggestionItems[id] = el;
        });
        return list;
    };
    SearchBar.prototype.hideNotIncludedItems = function (list) {
        var _this = this;
        this.searchResults.querySelectorAll('.search-result__item').forEach(function (el) {
            var elementId = el.dataset.id;
            if (!elementId || list.includes(parseInt(elementId))) {
                return;
            }
            _this.shadowCache.appendChild(el);
        });
    };
    SearchBar.prototype.showLoader = function () {
        this.icon.classList.add('icon--loading');
    };
    SearchBar.prototype.hideLoader = function () {
        this.icon.classList.remove('icon--loading');
    };
    return SearchBar;
}());
export { SearchBar };
export default function initSearchBar() {
    new SearchBar(document.getElementById('main-search'), document.getElementById('search-result'), document.getElementById('search-result-not-found'), document.getElementById('search-result-brands'), document.getElementById('search-result-products'), document.querySelector('.top-search__icon .icon'));
}
