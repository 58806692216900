var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { pluralize } from '../utils';
var LABELS_DAYS = ['день', 'дня', 'дней'];
var LABELS_HOURS = ['час', 'часа', 'часов'];
var LABELS_MINUTES = ['минута', 'минуты', 'минут'];
var LABELS_SECONDS = ['секунда', 'секунды', 'секунд'];
var Element = /** @class */ (function () {
    function Element(parent, selector, labels) {
        this.element = parent.querySelector(selector);
        this.labelElement = this.element.nextElementSibling;
        this.labels = labels;
        this.label = null;
        this.value = null;
    }
    Element.prototype.set = function (value) {
        if (this.value === value) {
            return;
        }
        this.value = value;
        this.updateElements();
    };
    Element.prototype.updateElements = function () {
        this.element.innerText = this.value.toString();
        var _a = __read(this.labels, 3), one = _a[0], two = _a[1], five = _a[2];
        var label = pluralize(this.value, one, two, five);
        if (this.label === label) {
            return;
        }
        this.label = this.labelElement.innerText = label;
    };
    return Element;
}());
export default function countdown(element) {
    if (!element) {
        return;
    }
    var days = new Element(element, '.countdown-days', LABELS_DAYS);
    var hours = new Element(element, '.countdown-hours', LABELS_HOURS);
    var minutes = new Element(element, '.countdown-minutes', LABELS_MINUTES);
    var seconds = new Element(element, '.countdown-seconds', LABELS_SECONDS);
    var deadline = Date.parse(element.dataset.deadline);
    tick();
    function tick() {
        var diff = Math.floor((deadline - Date.now()) / 1000);
        if (diff < 0) {
            days.set(0);
            hours.set(0);
            minutes.set(0);
            seconds.set(0);
        }
        else {
            seconds.set(diff % 60);
            minutes.set(Math.floor(diff / 60) % 60);
            hours.set(Math.floor(diff / 60 / 60) % 24);
            days.set(Math.floor(diff / 60 / 60 / 24));
            setTimeout(function () { return window.requestAnimationFrame ? requestAnimationFrame(tick) : tick(); }, 500);
        }
    }
}
